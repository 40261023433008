import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Services from "../components/Repeating/Services";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";

const Page = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Manufacturing Services | Fiberglass | Painted Rhino"
            description="Painted Rhino is a fiberglass company with solutions for all of your manufacturing needs. Learn more about our products and manufacturing services."
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <Services
            className="mb-22 pt-8 md:mb-32 md:pt-12"
            headerHeading="Our Manufacturing Services"
            headingLevel="h1"
            headerText="Painted Rhino has solutions for all of your manufacturing needs. Learn more about our products and manufacturing services."
         />

         <CTABackgroundImage />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Services_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Services_Twitter.jpg" }) {
         publicURL
      }
   }
`;

export default Page;
