import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Services = ({ className, headerHeading, headerText, headingLevel }) => {
   const data = useStaticQuery(graphql`
      {
         fiberglassComposites: file(relativePath: { eq: "repeating/services/fiberglass-composites.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 554) {
                  ...GatsbyImageSharpFluid_withWebp
               }
            }
         }
         vacuumFormingThermoforming: file(relativePath: { eq: "repeating/services/vacuum-forming-thermoforming.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 554) {
                  ...GatsbyImageSharpFluid_withWebp
               }
            }
         }
         injectionUrethaneRotocasting: file(relativePath: { eq: "repeating/services/injection-urethane-rotocasting.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 554) {
                  ...GatsbyImageSharpFluid_withWebp
               }
            }
         }
         lrtmComposites: file(relativePath: { eq: "repeating/services/lrtm-composites.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 554) {
                  ...GatsbyImageSharpFluid_withWebp
               }
            }
         }
         conceptDesignEngineering: file(relativePath: { eq: "repeating/services/concept-design-engineering.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 554) {
                  ...GatsbyImageSharpFluid_withWebp
               }
            }
         }
         toolingMoldMakingMetalWork: file(relativePath: { eq: "repeating/services/tooling-mold-making-metal-work.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 554) {
                  ...GatsbyImageSharpFluid_withWebp
               }
            }
         }
         mobileInHouseFiberglassRepair: file(relativePath: { eq: "repeating/services/mobile-in-house-fiberglass-repair.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 554) {
                  ...GatsbyImageSharpFluid_withWebp
               }
            }
         }
         specialEffectsPropsTheming: file(relativePath: { eq: "repeating/services/special-effects-props-theming.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 554) {
                  ...GatsbyImageSharpFluid_withWebp
               }
            }
         }
      }
   `);

   const HeadingTag = headingLevel || "h2";

   return (
      <section className={`${className}`}>
         <div className="container">
            <header className="mb-8 md:mb-12">
               <HeadingTag>{headerHeading}</HeadingTag>
               <p className="mb-0 md:text-lg">{headerText}</p>
            </header>

            <div className="grid grid-cols-2 gap-x-4 gap-y-10 md:gap-x-8 md:gap-y-16 lg:grid-cols-4">
               <div className="group relative">
                  <div className="mb-5 overflow-hidden">
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                        fluid={data.fiberglassComposites.childImageSharp.fluid}
                        alt="Fiberglass & Composites"
                     />
                  </div>
                  <h3 className="mb-0 text-base font-semibold">Fiberglass & Composites</h3>
                  <AniLink fade to="/fiberglass-composite-parts-manufacturing/">
                     <span className="link-overlay"></span>
                  </AniLink>
               </div>
               <div className="group relative">
                  <div className="mb-5 overflow-hidden">
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                        fluid={data.vacuumFormingThermoforming.childImageSharp.fluid}
                        alt="Vacuum Forming & Thermoforming"
                     />
                  </div>
                  <h3 className="mb-0 text-base font-semibold">Vacuum Forming & Thermoforming</h3>
                  <AniLink fade to="/vacuum-forming-thermoforming-company/">
                     <span className="link-overlay"></span>
                  </AniLink>
               </div>
               <div className="group relative">
                  <div className="mb-5 overflow-hidden">
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                        fluid={data.injectionUrethaneRotocasting.childImageSharp.fluid}
                        alt="Injection Urethane & Rotocasting"
                     />
                  </div>
                  <h3 className="mb-0 text-base font-semibold">Injection Urethane & Rotocasting</h3>
                  <AniLink fade to="/injection-urethane-rotocasting/">
                     <span className="link-overlay"></span>
                  </AniLink>
               </div>
               <div className="group relative">
                  <div className="mb-5 overflow-hidden">
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                        fluid={data.lrtmComposites.childImageSharp.fluid}
                        alt="LRTM Composites "
                     />
                  </div>
                  <h3 className="mb-0 text-base font-semibold">LRTM Composites </h3>
                  <AniLink fade to="/light-resin-transfer-molding/">
                     <span className="link-overlay"></span>
                  </AniLink>
               </div>
               <div className="group relative">
                  <div className="mb-5 overflow-hidden">
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                        fluid={data.conceptDesignEngineering.childImageSharp.fluid}
                        alt="Concept Design & Engineering"
                     />
                  </div>
                  <h3 className="mb-0 text-base font-semibold">Concept Design & Engineering</h3>
                  <AniLink fade to="/concept-design-engineering-company/">
                     <span className="link-overlay"></span>
                  </AniLink>
               </div>
               <div className="group relative">
                  <div className="mb-5 overflow-hidden">
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                        fluid={data.toolingMoldMakingMetalWork.childImageSharp.fluid}
                        alt="Tooling, Mold Making & Metal Work"
                     />
                  </div>
                  <h3 className="mb-0 text-base font-semibold">Tooling, Mold Making & Metal Work</h3>
                  <AniLink fade to="/tooling-mold-making-metal-work/">
                     <span className="link-overlay"></span>
                  </AniLink>
               </div>
               <div className="group relative">
                  <div className="mb-5 overflow-hidden">
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                        fluid={data.mobileInHouseFiberglassRepair.childImageSharp.fluid}
                        alt="Mobile & In-House Fiberglass Repair"
                     />
                  </div>
                  <h3 className="mb-0 text-base font-semibold">Mobile & In-House Fiberglass Repair</h3>
                  <AniLink fade to="/fiberglass-repair-company/">
                     <span className="link-overlay"></span>
                  </AniLink>
               </div>
               <div className="group relative">
                  <div className="mb-5 overflow-hidden">
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="scale-100 transform transition-all duration-500 ease-linear group-hover:scale-105"
                        fluid={data.specialEffectsPropsTheming.childImageSharp.fluid}
                        alt="Special Effects Props & Theming"
                     />
                  </div>
                  <h3 className="mb-0 text-base font-semibold">Special Effects Props & Theming</h3>
                  <AniLink fade to="/special-effects-props/">
                     <span className="link-overlay"></span>
                  </AniLink>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Services;
